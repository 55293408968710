<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-button variant="primary" @click="addModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="items" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="warning" @click="openEditSTB(props.row)">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button variant="danger" class="ml-1" @click="removeItem(props.row.id)">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </b-button>
                    </div>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label for="name">Name:</label>
                    <b-form-input
                        id="name"
                        type="text"
                        placeholder="Name"
                        v-model="addObject.name"
                    />
                </b-form-group>

                <b-form-group
                >
                    <label>MAC:<fa class="ml-1" icon="question-circle" v-b-tooltip="'MAC Address, written on STB'" /></label>
                    <cleave
                        id="mac"
                        v-model="addObject.mac"
                        class="form-control"
                        :raw="false"
                        :options="cleaveConfig"
                        placeholder="XX:XX:XX:XX:XX:XX"
                    />
                </b-form-group>

                <b-form-group>
                    <label for="usageTypes">Usage type:</label>
                    <v-select
                        id="usageTypes"
                        v-model="addObject.usage_type"
                        :reduce="usageType => usageType.id"
                        label="name"
                        :options="usageTypes"
                    />

                </b-form-group>

                <b-form-group v-if="addObject.usage_type === 0">
                    <label for="room">Room:</label>
                    <v-select
                        id="room"
                        v-model="addObject.room_id"
                        :reduce="room => room.id"
                        :filter="filterRooms"
                        :options="rooms"
                    >
                        <template slot="option" slot-scope="room">
                            <span>{{room.location + ' - ' + room.name}}</span>
                        </template>

                        <template slot="selected-option" slot-scope="room">
                            <span>{{room.location + ' - ' + room.name}}</span>
                        </template>
                    </v-select>

                </b-form-group>

                <b-form-group v-if="addObject.usage_type === 1">
                    <label>Digital signage:</label>
                    <v-select
                        v-model="addObject.signage_id"
                        :reduce="signage => signage.id"
                        label="name"
                        :clearable="false"
                        :options="signages"
                    />

                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addItem" :disabled="!((new RegExp('[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}')).test(addObject.mac))">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>

        <b-modal title="Edit STB" v-model="editModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label for="nameEdit">Name:</label>
                    <b-form-input
                        id="nameEdit"
                        type="text"
                        placeholder="Name"
                        v-model="editObject.name"
                    />
                </b-form-group>


                <b-form-group>
                    <label for="usageTypesEdit">Usage type:</label>
                    <v-select
                        id="usageTypesEdit"
                        v-model="editObject.usage_type"
                        :reduce="usageType => usageType.id"
                        label="name"
                        :options="usageTypes"
                    />

                </b-form-group>

                <b-form-group v-if="editObject.usage_type === 0">
                    <label for="roomEdit">Room:</label>
                    <v-select
                        id="roomEdit"
                        v-model="editObject.room_id"
                        :reduce="room => room.id"
                        :filter="filterRooms"
                        :options="rooms"
                    >
                        <template slot="option" slot-scope="room">
                            <span>{{room.location + ' - ' + room.name}}</span>
                        </template>

                        <template slot="selected-option" slot-scope="room">
                            <span>{{room.location + ' - ' + room.name}}</span>
                        </template>
                    </v-select>

                </b-form-group>

                <b-form-group v-if="editObject.usage_type === 1">
                    <label>Digital signage:</label>
                    <v-select
                        v-model="editObject.signage_id"
                        :reduce="signage => signage.id"
                        label="name"
                        :clearable="false"
                        :options="signages"
                    />

                </b-form-group>

                <hr/>
            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="editModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="warning" @click="editItem">
                    <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import { BOverlay, BCard, BButton, BModal, BFormInput, BFormGroup } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import BasicTable from '@/views/components/BasicTable'
    import Cleave from 'vue-cleave-component'

    export default {
        components: {
            BOverlay,
            BCard,
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BasicTable,
            vSelect,
            Cleave
        },
        data() {
            return {

                items: [],
                rooms: [],
                signages: [],
                dataLoaded: false,

                columns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    {
                        label: 'IP',
                        displayType: 0,
                        field: 'ip',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search IP'
                        }
                    },
                    {
                        label: 'LAN IP',
                        displayType: 0,
                        field: 'lan_ip',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search LAN IP'
                        }
                    },
                    {
                        label: 'MAC',
                        displayType: 0,
                        field: 'mac',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search MAC'
                        }
                    },
                    {
                        label: 'PIN',
                        displayType: 0,
                        field: 'pin_formatted',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search PIN'
                        }
                    },
                    {
                        label: 'Usage type',
                        displayType: 0,
                        field(rowObj) {
                            return (['Guest STB', 'Digital signage'])[rowObj.usage_type]
                        },
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search usage type'
                        }
                    },
                    {
                        label: 'Room/Signage',
                        displayType: 0,
                        field: this.roomSignageRenderer,
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Room/Signage'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                usageTypes: [
                    {
                        id: 0,
                        name: 'Guest TV'
                    },
                    {
                        id: 1,
                        name: 'Digital signage'
                    }
                ],

                cleaveConfig: {
                    delimiter: ':',
                    blocks: [2, 2, 2, 2, 2, 2],
                    uppercase: true
                },

                addObject: {},
                imageFile: null,

                editObject : {},
                addModalActive: false,
                editModalActive: false

            }
        },
        methods: {
            openEditSTB(stb) {
                this.editObject = JSON.parse(JSON.stringify(stb))
                this.editModalActive = true
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/stb')
                loadPromise.then(function(response) {
                    thisIns.items = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                const roomsPromise = this.$http.get('/api/management/v1/room')
                roomsPromise.then(function(response) {
                    thisIns.rooms = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                const signageListPromise = this.$http.get('/api/management/v1/digital_signage')
                signageListPromise.then(function(response) {
                    thisIns.signages = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise, roomsPromise, signageListPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })

            },
            addItem() {
                const thisIns = this
                const loadPromise = this.$http.post('/api/management/v1/stb', this.addObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('STB added')
                    thisIns.addModalActive = false
                    thisIns.addObject = {}
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            editItem() {
                const thisIns = this
                const loadPromise = this.$http.put(`/api/management/v1/stb/${this.editObject.id}`, this.editObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('STB saved')
                    thisIns.editModalActive = false
                    thisIns.editObject = {}
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            removeItem(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/stb/${  id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('STB removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            filterRooms(rooms, search) {
                return rooms.filter(room => room.location.toLowerCase().includes(search.toLowerCase()) || room.name.toLowerCase().includes(search.toLowerCase()))
            },
            roomSignageRenderer(rowObj) {
                if (rowObj.usage_type === 0) {
                    return this.getRoomName(rowObj.room_id)
                } else {
                    return this.getSignageName(rowObj.signage_id)
                }
            },
            getRoomName(roomId) {
                const room = this.rooms.find(room => room.id === roomId)

                return (room) ? `${room.location  } - ${  room.name}` : 'Unknown'
            },
            getSignageName(signageId) {
                return (this.signages.find(signage => signage.id === signageId) || {name: 'Unknown'}).name
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
